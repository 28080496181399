/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import Main from '../../components/layout/Main';
import './devSchedule.scss';
import ProjectScheduleTable from '../../components/ProjectScheduleTable';

const DevSchedule = () => {
    const { devSchedule, isFetching } = useSelector((state) => state?.dashboard) || {};

    const currentYear = new Date().getFullYear();

    const renderScheduleTable = () => (
        <div style={{ flex: 1, width: '100%', margin: 5 }}>
            <ProjectScheduleTable
                data={Array.isArray(devSchedule?.schedule) ? devSchedule.schedule : []}
                lastUpdated={devSchedule?.update_at}
                isFetching={isFetching} />
        </div>
    );
    return (
        <Main
            className="dev-schedule-page"
            title={null}
            headerTitle={null}
            menuWidth={350}
            menuPlacement="right"
            floatingHeader={false}
            footerContent={(
                <div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>
                    &copy; Scorchsoft Ltd {currentYear}
                </div>
            )}
            floatingFooter
            onLogout={null}>
            <div style={{ marginInline: '50px' }}>
                <div style={{ marginTop: '20px', marginBottom: '20px', alignContent: 'center' }}>
                    <h1>Developer Schedule</h1>
                </div>

                <div style={{ marginBottom: '100px' }}>
                    {renderScheduleTable()}
                </div>
            </div>
        </Main>
    );
};

export default DevSchedule;

DevSchedule.propTypes = {};

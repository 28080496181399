const SelectionFilter = (dataIndex, dataSource) => (
    {
        filters: dataSource?.map(record => ({
            text: record[dataIndex],
            value: record[dataIndex],
        })).filter((record, index, array) => array.findIndex((s) => record.text === s.text) === index),
        onFilter: (value, record) => record[dataIndex] === value,
        filterMode: 'tree',
    }
);

export default SelectionFilter;

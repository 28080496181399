const view = [
    { view: 'overview' },
];
const ROLE_LEAD_DEVELOPER = 'lead developer';
const ROLE_PROJECT_TEAM = 'project team';
const ROLE_DEVELOPER = 'developer';
const TYPE_SPRINT = 'Sprint';
const TYPE_RETAINER = 'Retainer';
const TYPE_FIXED = 'Waterfall';
const TYPE_SPRINT_VALUE = 30;
const TYPE_RETAINER_VALUE = 20;
const TYPE_FIXED_VALUE = 10;
const STATUS_ACTIVE = 'active';
const STATUS_INACTIVE = 'inactive';
const projectType = {
    [TYPE_SPRINT_VALUE]: TYPE_SPRINT,
    [TYPE_RETAINER_VALUE]: TYPE_RETAINER,
    [TYPE_FIXED_VALUE]: TYPE_FIXED,
};
const type = [
    { sprint: 30 },
    { retainer: 20 },
    { fixed: 10 },
];
export default view;

const colors = {
    COLOR_RED: 'red',
    COLOR_AMBER: 'amber',
    COLOR_GREEN: 'green',
};
const pageSizeOptions = ['10', '50', '100', '500', '1000'];
const defaultPageSize = 100;

const colorsHex = {
    COLOR_RED: '#cf2a27',
    COLOR_AMBER: '#ff9900',
    COLOR_GREEN: '#009e0f',
};

const criteriaNames = {
    CRITERIA_PIPELINE_COMPLIANCE: 'pipeline_compliance',
    CRITERIA_BRANCH_RESTRICTION: 'branch_restriction',
    CRITERIA_PULL_REQUEST: 'pull_request',
    CRITERIA_BUILD: 'build',
    CRITERIA_NPS_SCORE: 'nps_score',
    CRITERIA_EBR: 'ebr',
    CRITERIA_BUG_RATE: 'bug_rate',
    CRITERIA_BUGSNAG_STABILITY: 'session_stability',
};

const criteriaNameDefinitions = {
    [criteriaNames.CRITERIA_PIPELINE_COMPLIANCE]: 'Project Pipeline Compliance Check',
    [criteriaNames.CRITERIA_BRANCH_RESTRICTION]: 'Project Branch Restriction Check',
    [criteriaNames.CRITERIA_PULL_REQUEST]: 'Project Pull Request Health Check',
    [criteriaNames.CRITERIA_BUILD]: 'Project Build Health Check',
    [criteriaNames.CRITERIA_BUGSNAG_STABILITY]: 'Project BugSnag User Stability Check',
};

const scoreStatus = {
    [colors.COLOR_GREEN]: 'passed',
    [colors.COLOR_RED]: 'failed',
    [colors.COLOR_AMBER]: 'failed',
};

export { type, projectType, colors, colorsHex, criteriaNames, criteriaNameDefinitions, scoreStatus, pageSizeOptions, defaultPageSize, STATUS_ACTIVE, STATUS_INACTIVE, ROLE_LEAD_DEVELOPER, ROLE_PROJECT_TEAM, ROLE_DEVELOPER };

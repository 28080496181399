import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import './pevDataTable.css';
import { Button } from 'antd';
import CoreTable from '../../components/CoreTable/CoreTable';
import { TYPES } from '../../components/CoreTable/config';
import { getNewPevSnapshotRequest, getPevInitialValuesRequest } from '../../core/agileProject/agileProjectActions';

const PevDataTable = () => {
    const { isFetching, settings, pevInitialValues, latestPevSnapshot } = useSelector((state) => state?.agileProject);

    const dispatch = useDispatch();
    const fullTableData = [
        {
            estimate: 'Total Dev',
            optimistic_estimate: pevInitialValues?.dev_optimistic?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            most_likely_estimate: pevInitialValues?.dev_most_likely?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            pessimistic_estimate: pevInitialValues?.dev_pessimistic?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            revised_estimate: latestPevSnapshot?.full_dev_revised?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            most_likely_to_revised_estimate: latestPevSnapshot?.full_dev_hours_difference?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            hours_remaining: latestPevSnapshot?.full_dev_hours_remaining?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
        },
        {
            estimate: 'Testing, QA & UAT Time',
            optimistic_estimate: pevInitialValues?.test_optimistic?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            most_likely_estimate: pevInitialValues?.test_most_likely?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            pessimistic_estimate: pevInitialValues?.test_pessimistic?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            revised_estimate: latestPevSnapshot?.full_test_revised?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            most_likely_to_revised_estimate: latestPevSnapshot?.full_test_hours_difference?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            hours_remaining: latestPevSnapshot?.full_test_hours_remaining?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
        },
        {
            estimate: 'PM Time',
            optimistic_estimate: pevInitialValues?.pm_optimistic?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            most_likely_estimate: pevInitialValues?.pm_most_likely?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            pessimistic_estimate: pevInitialValues?.pm_pessimistic?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            revised_estimate: latestPevSnapshot?.full_pm_revised?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            most_likely_to_revised_estimate: latestPevSnapshot?.full_pm_hours_difference?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            hours_remaining: latestPevSnapshot?.full_pm_hours_remaining?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
        },
        {
            estimate: 'Dev PM Time',
            optimistic_estimate: pevInitialValues?.dev_pm_optimistic?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            most_likely_estimate: pevInitialValues?.dev_pm_most_likely?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            pessimistic_estimate: pevInitialValues?.dev_pm_pessimistic?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            revised_estimate: latestPevSnapshot?.full_dev_pm_revised?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            most_likely_to_revised_estimate: latestPevSnapshot?.full_dev_pm_hours_difference?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            hours_remaining: latestPevSnapshot?.full_dev_pm_hours_remaining?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
        },
        {
            estimate: 'Total Hours',
            optimistic_estimate: pevInitialValues?.total_hours_optimistic?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            most_likely_estimate: pevInitialValues?.total_hours_most_likely?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            pessimistic_estimate: pevInitialValues?.total_hours_pessimistic?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            revised_estimate: latestPevSnapshot?.full_total_hours_revised?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            most_likely_to_revised_estimate: latestPevSnapshot?.full_total_hours_difference?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            hours_remaining: latestPevSnapshot?.full_total_hours_remaining?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
        },
        {
            estimate: 'Total Cost',
            optimistic_estimate: pevInitialValues?.total_cost_optimistic ? `£ ${pevInitialValues?.total_cost_optimistic?.toFixed(2)}` : '',
            most_likely_estimate: pevInitialValues?.total_cost_most_likely ? `£ ${pevInitialValues?.total_cost_most_likely?.toFixed(2)}` : '',
            pessimistic_estimate: pevInitialValues?.total_cost_pessimistic ? `£ ${pevInitialValues?.total_cost_pessimistic?.toFixed(2)}` : '',
            revised_estimate: latestPevSnapshot?.full_total_cost_revised ? `£ ${latestPevSnapshot?.full_total_cost_revised?.toFixed(2)}` : '',
            most_likely_to_revised_estimate: latestPevSnapshot?.full_total_cost_difference ? `£ ${latestPevSnapshot?.full_total_cost_difference?.toFixed(2)}` : '',
            hours_remaining: latestPevSnapshot?.full_total_cost_remaining ? `£ ${latestPevSnapshot?.full_total_cost_remaining?.toFixed(2)}` : '',
        },
    ];

    const overviewTableData = [
        {
            overview_time: 'Dev Time, Testing, QA & UAT Time',
            total_dev_estimate: latestPevSnapshot?.overview_dev_and_test_total_dev_estimate?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            total_dev_remaining: latestPevSnapshot?.overview_dev_and_test_total_dev_remaining?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            total_dev_completed: latestPevSnapshot?.overview_dev_and_test_total_dev_completed?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            actual_time_used: latestPevSnapshot?.overview_dev_and_test_actual_time_used?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            total_cost: latestPevSnapshot?.overview_dev_and_test_total_cost_as_originally_estimated?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            time_used: latestPevSnapshot?.overview_dev_and_test_time_used_difference?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            delta: latestPevSnapshot?.overview_dev_and_test_time_used_percentage ? `${latestPevSnapshot?.overview_dev_and_test_time_used_percentage?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })} %` : '',
            updated_hours: latestPevSnapshot?.overview_dev_and_test_updated_hours?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
        },
        {
            overview_time: 'PM Time',
            total_dev_estimate: latestPevSnapshot?.overview_pm_total_dev_estimate?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            total_dev_remaining: latestPevSnapshot?.overview_pm_total_dev_remaining?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            total_dev_completed: latestPevSnapshot?.overview_pm_total_dev_completed?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            actual_time_used: latestPevSnapshot?.overview_pm_actual_time_used?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            total_cost: latestPevSnapshot?.overview_pm_total_cost_as_originally_estimated?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            time_used: latestPevSnapshot?.overview_pm_time_used_difference?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            delta: latestPevSnapshot?.overview_pm_time_used_percentage ? `${latestPevSnapshot?.overview_pm_time_used_percentage?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })} %` : '',
            updated_hours: latestPevSnapshot?.overview_pm_updated_hours?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
        },
        {
            overview_time: 'Dev PM Time',
            total_dev_estimate: latestPevSnapshot?.overview_dev_pm_total_dev_estimate?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            total_dev_remaining: latestPevSnapshot?.overview_dev_pm_total_dev_remaining?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            total_dev_completed: latestPevSnapshot?.overview_dev_pm_total_dev_completed?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            actual_time_used: latestPevSnapshot?.overview_dev_pm_actual_time_used?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            total_cost: latestPevSnapshot?.overview_dev_pm_total_cost_as_originally_estimated?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            time_used: latestPevSnapshot?.overview_dev_pm_time_used_difference?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            delta: latestPevSnapshot?.overview_dev_pm_time_used_percentage ? `${latestPevSnapshot?.overview_dev_pm_time_used_percentage?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })} %` : '',
            updated_hours: latestPevSnapshot?.overview_dev_pm_updated_hours?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
        },
        {
            overview_time: 'Total Time',
            total_dev_estimate: latestPevSnapshot?.overview_total_total_dev_estimate?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            total_dev_remaining: latestPevSnapshot?.overview_total_total_dev_remaining?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            total_dev_completed: latestPevSnapshot?.overview_total_total_dev_completed?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            actual_time_used: latestPevSnapshot?.overview_total_actual_time_used?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            total_cost: latestPevSnapshot?.overview_total_total_cost_as_originally_estimated?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            time_used: latestPevSnapshot?.overview_total_time_used_difference?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            delta: latestPevSnapshot?.overview_total_time_used_percentage ? `${latestPevSnapshot?.overview_total_time_used_percentage?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })} %` : '',
            updated_hours: latestPevSnapshot?.overview_total_updated_hours?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
        },
        {
            overview_time: 'Percentage',
            total_dev_estimate: latestPevSnapshot?.overview_percentage_total_dev_estimate?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            total_dev_remaining: latestPevSnapshot?.overview_percentage_total_dev_remaining?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            total_dev_completed: latestPevSnapshot?.overview_percentage_total_dev_completed?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            actual_time_used: 'Total Cost:',
            total_cost: latestPevSnapshot?.overview_remaining_hours_total_cost ? `£ ${latestPevSnapshot?.overview_remaining_hours_total_cost?.toFixed(2)}` : '',
            time_used: '',
            delta: 'Total Cost:',
            updated_hours: latestPevSnapshot?.overview_updated_hours_total_cost ? `£ ${latestPevSnapshot?.overview_updated_hours_total_cost?.toFixed(2)}` : '',
        },
    ];

    const mockTotalTimeData = [
        { actual_time: 'Time spent on Tickets' },
        { actual_time: 'PM Time' },
        { actual_time: 'Dev Tasks Time' },
        { actual_time: 'Total Hours' },
        { actual_time: 'Total Cost' },
        { actual_time: 'Invoices paid to date' },
        { actual_time: 'Amount remaining to invoice based on time spent' },
    ];

    const comparisonTableData = [
        {
            estimate: 'Optimistic Estimate',
            hours: latestPevSnapshot?.comparison_optimistic_budget_hours?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            cost: latestPevSnapshot?.comparison_optimistic_budget_cost ? `£ ${latestPevSnapshot?.comparison_optimistic_budget_cost?.toFixed(2)}` : '',
            comparison_revised_hours: latestPevSnapshot?.comparison_revised_hours?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            comparison_revised_cost: latestPevSnapshot?.comparison_revised_cost ? `£ ${latestPevSnapshot?.comparison_revised_cost?.toFixed(2)}` : '',
            comparison_delta_cost: latestPevSnapshot?.comparison_projection_optimistic_delta_cost ? `£ ${latestPevSnapshot?.comparison_projection_optimistic_delta_cost?.toFixed(2)}` : '',
            comparison_delta_percent_cost: latestPevSnapshot?.comparison_projection_optimistic_delta_percentage_cost ? `${latestPevSnapshot?.comparison_projection_optimistic_delta_percentage_cost?.toFixed(2)} %` : '',
            projection_revised_hours: latestPevSnapshot?.comparison_projection_revised_hours?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            projection_revised_cost: latestPevSnapshot?.comparison_projection_revised_cost ? `£ ${latestPevSnapshot?.comparison_projection_revised_cost?.toFixed(2)}` : '',
            projection_delta_cost: latestPevSnapshot?.comparison_projection_optimistic_delta_cost ? `£ ${latestPevSnapshot?.comparison_projection_optimistic_delta_cost?.toFixed(2)}` : '',
            projection_delta_percent_cost: latestPevSnapshot?.comparison_projection_optimistic_delta_percentage_cost ? `${latestPevSnapshot?.comparison_projection_optimistic_delta_percentage_cost?.toFixed(2)} %` : '',
        },
        {
            estimate: 'Most Likely Estimate',
            hours: latestPevSnapshot?.comparison_most_likely_budget_hours?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            cost: latestPevSnapshot?.comparison_most_likely_budget_cost ? `£ ${latestPevSnapshot?.comparison_most_likely_budget_cost?.toFixed(2)}` : '',
            comparison_revised_hours: latestPevSnapshot?.comparison_revised_hours?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            comparison_revised_cost: latestPevSnapshot?.comparison_revised_cost ? `£ ${latestPevSnapshot?.comparison_revised_cost?.toFixed(2)}` : '',
            comparison_delta_cost: latestPevSnapshot?.comparison_projection_most_likely_delta_cost ? `£ ${latestPevSnapshot?.comparison_projection_most_likely_delta_cost?.toFixed(2)}` : '',
            comparison_delta_percent_cost: latestPevSnapshot?.comparison_projection_most_likely_delta_percentage_cost ? `${latestPevSnapshot?.comparison_projection_most_likely_delta_percentage_cost?.toFixed(2)} %` : '',
            projection_revised_hours: latestPevSnapshot?.comparison_projection_revised_hours?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            projection_revised_cost: latestPevSnapshot?.comparison_projection_revised_cost ? `£ ${latestPevSnapshot?.comparison_projection_revised_cost?.toFixed(2)}` : '',
            projection_delta_cost: latestPevSnapshot?.comparison_projection_most_likely_delta_cost ? `£ ${latestPevSnapshot?.comparison_projection_most_likely_delta_cost?.toFixed(2)}` : '',
            projection_delta_percent_cost: latestPevSnapshot?.comparison_projection_most_likely_delta_percentage_cost ? `${latestPevSnapshot?.comparison_projection_most_likely_delta_percentage_cost?.toFixed(2)} %` : '',
        },
        {
            estimate: 'Revised Estimate',
            hours: latestPevSnapshot?.comparison_revised_budget_hours?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            cost: latestPevSnapshot?.comparison_revised_budget_cost ? `£ ${latestPevSnapshot?.comparison_revised_budget_cost?.toFixed(2)}` : '',
            comparison_revised_hours: latestPevSnapshot?.comparison_revised_hours?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            comparison_revised_cost: latestPevSnapshot?.comparison_revised_cost ? `£ ${latestPevSnapshot?.comparison_revised_cost?.toFixed(2)}` : '',
            comparison_delta_cost: latestPevSnapshot?.comparison_projection_revised_delta_cost ? `£ ${latestPevSnapshot?.comparison_projection_revised_delta_cost?.toFixed(2)}` : '',
            comparison_delta_percent_cost: latestPevSnapshot?.comparison_projection_revised_delta_percentage_cost ? `${latestPevSnapshot?.comparison_projection_revised_delta_percentage_cost?.toFixed(2)} %` : '',
            projection_revised_hours: latestPevSnapshot?.comparison_projection_revised_hours?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            projection_revised_cost: latestPevSnapshot?.comparison_projection_revised_cost ? `£ ${latestPevSnapshot?.comparison_projection_revised_cost?.toFixed(2)}` : '',
            projection_delta_cost: latestPevSnapshot?.comparison_projection_revised_delta_cost ? `£ ${latestPevSnapshot?.comparison_projection_revised_delta_cost?.toFixed(2)}` : '',
            projection_delta_percent_cost: latestPevSnapshot?.comparison_projection_revised_delta_percentage_cost ? `${latestPevSnapshot?.comparison_projection_revised_delta_percentage_cost?.toFixed(2)} %` : '',
        },
        {
            estimate: 'Pessimistic Estimate',
            hours: latestPevSnapshot?.comparison_pessimistic_budget_hours?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            cost: latestPevSnapshot?.comparison_pessimistic_budget_cost ? `£ ${latestPevSnapshot?.comparison_pessimistic_budget_cost?.toFixed(2)}` : '',
            comparison_revised_hours: latestPevSnapshot?.comparison_revised_hours?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            comparison_revised_cost: latestPevSnapshot?.comparison_revised_cost ? `£ ${latestPevSnapshot?.comparison_revised_cost?.toFixed(2)}` : '',
            comparison_delta_cost: latestPevSnapshot?.comparison_projection_pessimistic_delta_cost ? `£ ${latestPevSnapshot?.comparison_projection_pessimistic_delta_cost?.toFixed(2)}` : '',
            comparison_delta_percent_cost: latestPevSnapshot?.comparison_projection_pessimistic_delta_percentage_cost ? `${latestPevSnapshot?.comparison_projection_pessimistic_delta_percentage_cost?.toFixed(2)} %` : '',
            projection_revised_hours: latestPevSnapshot?.comparison_projection_revised_hours?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            projection_revised_cost: latestPevSnapshot?.comparison_projection_revised_cost ? `£ ${latestPevSnapshot?.comparison_projection_revised_cost?.toFixed(2)}` : '',
            projection_delta_cost: latestPevSnapshot?.comparison_projection_pessimistic_delta_cost ? `£ ${latestPevSnapshot?.comparison_projection_pessimistic_delta_cost?.toFixed(2)}` : '',
            projection_delta_percent_cost: latestPevSnapshot?.comparison_projection_pessimistic_delta_percentage_cost ? `${latestPevSnapshot?.comparison_projection_pessimistic_delta_percentage_cost?.toFixed(2)} %` : '',
        },
    ];

    const renderBillingPeriodTable = () => (
        <div>
            <Button
                type="primary"
                style={{ marginBottom: '20px' }}
                onClick={() => {
                    dispatch(getNewPevSnapshotRequest({ projectId: settings?.id }));
                    dispatch(getPevInitialValuesRequest({ projectId: settings?.id }));
                }}
                disabled={Object.keys(pevInitialValues).length === 0}>Generate New Pev Snapshot
            </Button>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
                <div style={{ width: '60%', marginRight: 20 }}>
                    <CoreTable
                        type={TYPES.ESTIMATE_PEV_DATA_TABLE}
                        dataSource={fullTableData}
                        isFetching={isFetching}
                        noFilter
                        tableLabel="Full Mobile App Estimate" />
                </div>
                <div style={{ width: '40%' }}>
                    <CoreTable
                        type={TYPES.TOTAL_TIME_SPENT_PEV_DATA_TABLE}
                        dataSource={mockTotalTimeData}
                        isFetching={isFetching}
                        noFilter
                        tableLabel="Total Time Spent (Ticket Time, PM & Dev PM Time) and Cost" />
                </div>
            </div>
            <div style={{ marginBottom: 20 }}>
                <CoreTable
                    type={TYPES.OVERVIEW_PEV_DATA_TABLE}
                    dataSource={overviewTableData}
                    isFetching={isFetching}
                    noFilter
                    tableLabel="Overview of Completed vs Remaining Work based on Revised Estimate" />
            </div>
            <CoreTable
                type={TYPES.COMPARISON_PEV_DATA_TABLE}
                dataSource={comparisonTableData}
                isFetching={isFetching}
                noFilter
                tableLabel="Comparison of the Four Budgets and the different between these Four and the Projected Estimate" />
        </div>
    );

    if (!pevInitialValues) {
        return null;
    }

    return (
        <div style={{ margin: 20, marginTop: 30 }}>
            {renderBillingPeriodTable()}
        </div>
    );
};

PevDataTable.propTypes = {
    onUpdate: PropTypes.func,
    view: PropTypes.string,
    viewId: PropTypes.number,
};

PevDataTable.defaultProps = {
    onUpdate: null,
    view: null,
    viewId: null,
};

export default PevDataTable;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import CoreTable from '../../../components/CoreTable/CoreTable';
import { TYPES } from '../../../components/CoreTable/config';
import { getBugSummaryRequest, getBugsRequest, getRetainerOverviewRequest, resetBugSummaryRequest } from '../../../core/agileProject/agileProjectActions';

const BugRateAnalysis = () => {
    const { settings, isFetching, bugSummary, bugs } = useSelector((state) => state?.agileProject);
    const [bugSuccess, setBugSuccess] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = useParams();
    const parsedId = parseInt(id, 10);
    const searchParams = new URLSearchParams(location.search);
    const view = searchParams.get('view');
    const [filterInfo, setFilterInfo] = useState(null);
    const [sorterInfo, setSorterInfo] = useState(null);
    const handleFilterChange = (filter) => {
        setFilterInfo(filter);
    };

    const handleSorterChange = (sorter) => {
        setSorterInfo(sorter);
    };
    useEffect(() => {
        if (!settings) {
            dispatch(getRetainerOverviewRequest({
                projectId: parsedId,
            }));
        }
    }, []);

    useEffect(() => {
        if (settings?.fixVersions?.length > 0) {
            if (settings?.fixVersions?.find(version => version.name === settings.projectName)) {
                dispatch(getBugSummaryRequest({ projectId: settings.id, fixVersions: settings.fixVersions.find(version => version.name === settings.projectName)?.id }));
                dispatch(getBugsRequest({ projectId: settings.id, fixVersion: settings.fixVersions.find(version => version.name === settings.projectName)?.id }));
                setBugSuccess(true);
            }
        }
        // dispatch the reset function to reset data
        dispatch(resetBugSummaryRequest());
    }, [view, settings]);

    const renderOverallBugRate = () => (
        <div style={{ width: '30%', minWidth: 300 }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                <h2>Bug Rate Analysis</h2>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                <h3>Overall Bug Rate: {bugSuccess ? bugSummary?.overallBugRate : 0} %</h3>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                <h3>QA Time Used: {bugSuccess ? bugSummary?.timeUsed : 0} h</h3>
            </div>
        </div>
    );

    const renderBugPriorityTable = () => (
        <div style={{ width: '70%', minWidth: 600 }}>
            <CoreTable
                type={TYPES.BUG_TICKET_BY_PRIORITY}
                dataSource={bugSuccess ? bugSummary?.bugPriority || [] : []}
                isFetching={isFetching}
                noFilter
                totalShown={false} />
        </div>
    );

    const renderBugComponentTable = () => (
        <div>
            <CoreTable
                type={TYPES.WATERFAL_BUG_COMPONENT}
                dataSource={bugSuccess ? bugSummary?.bugComponent || [] : []}
                isFetching={isFetching}
                noFilter
                totalShown={false} />
        </div>
    );

    return (
        <div style={{ margin: 50, marginTop: 20 }}>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
                { renderOverallBugRate() }
                { renderBugPriorityTable() }
            </div>
            <div style={{ marginTop: 20 }}>
                { renderBugComponentTable() }
            </div>
            <div style={{ marginTop: 20 }}>
                {
                    !isFetching && (
                        <CoreTable
                            type={TYPES.WATERFALL_BUG_RATE_ANALAYSIS}
                            dataSource={bugSuccess ? bugs : []}
                            isFetching={isFetching}
                            printable={false}
                            projectName={settings?.projectName}
                            clientName={settings?.clientName}
                            filterInfo={filterInfo}
                            handleFilterChange={handleFilterChange}
                            sorterInfo={sorterInfo}
                            handleSorterChange={handleSorterChange} />
                    )
                }
            </div>
        </div>
    );
};

export default BugRateAnalysis;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Table } from 'antd';
import '../pages/Schedule/devSchedule.scss';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { getDevScheduleRequest } from '../core/dashboard/dashboardActions';
import { ArrowLeftGroupIcon, ArrowRightGroupIcon } from './Elements/CustomSVGIcon';

const ProjectScheduleTable = ({ data, lastUpdated, isFetching }) => {
    const minWeek = moment().startOf('isoWeek').subtract(1, 'weeks').format('YYYY-MM-DD'); // Maximum backward
    const maxWeek = moment().startOf('isoWeek').add(1, 'weeks').format('YYYY-MM-DD'); // Maximum forward
    const [isDisabledLeft, setIsDisabledLeft] = useState(false);
    const [isDisabledRight, setIsDisabledRight] = useState(false);
    const dispatch = useDispatch();

    const [week, setWeek] = useState(moment().startOf('isoWeek').format('YYYY-MM-DD'));
    useEffect(() => {
        dispatch(getDevScheduleRequest({ week }));
    }, [week]);

    const handleLeftClick = () => {
        const newWeek = moment(week).subtract(1, 'weeks').format('YYYY-MM-DD');
        if (newWeek >= minWeek) {
            setWeek(newWeek);
            setIsDisabledLeft(false);
        }
        if (newWeek === minWeek) {
            setIsDisabledLeft(true);
        }
        if (newWeek <= maxWeek) {
            setIsDisabledRight(false);
        }
    };

    const handleRightClick = () => {
        const newWeek = moment(week).add(1, 'weeks').format('YYYY-MM-DD');
        if (newWeek <= maxWeek) {
            setWeek(newWeek);
            setIsDisabledRight(false);
        }
        if (newWeek === maxWeek) {
            setIsDisabledRight(true);
        }
        if (newWeek >= minWeek) {
            setIsDisabledLeft(false);
        }
    };
    const formatDate = (date) => {
        if (!date) return '';
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Intl.DateTimeFormat('en-GB', options).format(new Date(date));
    };
    const devScheduleTableHeader = () => (
        <Row className="header-style" justify="center" align="middle">
            <Button icon={<ArrowLeftGroupIcon />} type="link" onClick={handleLeftClick} disabled={isFetching} className={`nav-button ${isDisabledLeft ? 'hidden-button' : ''}`} />
            <h4 className="week-text">Week Commencing: {formatDate(week)}</h4>
            <Button icon={<ArrowRightGroupIcon />} type="link" onClick={handleRightClick} disabled={isFetching} className={`nav-button ${isDisabledRight ? 'hidden-button' : ''}`} />
        </Row>
    );
    const columns = [
        {
            title: 'Developer',
            dataIndex: 'name',
            key: 'name',
            className: 'header-column',
        },
        {
            title: (
                <div className="project-schedule-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span className="header-title">Project Schedule</span>
                    {lastUpdated && <span className="last-updated">Last Updated: {lastUpdated}</span>}
                </div>
            ),
            dataIndex: 'schedules',
            key: 'schedules',
            className: 'header-column striped-table',
            render: schedule => (
                <div className="schedule-container">
                    {schedule.map(item => {
                        if (item.totalDays === 0) return null;
                        return (
                            <div
                                key={item.title}
                                className="schedule-box"
                                style={{
                                    backgroundColor: item.color,
                                    flexBasis: `${(item.totalDays / 5) * 100}%`,
                                }}>
                                <div className="schedule-item">
                                    <div className="title-tag">{item.projectCode}</div>
                                    <div>
                                        {item.totalDays} {item.totalDays > 1 ? 'Days' : 'Day'}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ),
        },
    ];
    return (
        <Table
            title={devScheduleTableHeader}
            columns={columns}
            dataSource={Array.isArray(data) ? data : []}
            pagination={false}
            loading={isFetching}
            rowClassName={() => 'project-schedule-row'} />
    );
};

ProjectScheduleTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        schedules: PropTypes.array,
    })),
    lastUpdated: PropTypes.string,
    isFetching: PropTypes.bool,
};

ProjectScheduleTable.defaultProps = {
    data: [],
    lastUpdated: '',
    isFetching: false,
};

export default ProjectScheduleTable;
